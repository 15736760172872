// automatically generated by the FlatBuffers compiler, do not modify

import { flatbuffers } from 'flatbuffers';
import * as NS7624605610262437867 from './Schema';
/**
 * ----------------------------------------------------------------------
 * Arrow File metadata
 *
 *
 * @constructor
 */
export namespace org.apache.arrow.flatbuf {
    export class Footer {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns Footer
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): Footer {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param Footer= obj
         * @returns Footer
         */
        static getRootAsFooter(bb: flatbuffers.ByteBuffer, obj?: Footer): Footer {
            return (obj || new Footer).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * @returns org.apache.arrow.flatbuf.MetadataVersion
         */
        version(): NS7624605610262437867.org.apache.arrow.flatbuf.MetadataVersion {
            let offset = this.bb!.__offset(this.bb_pos, 4);
            return offset ? /**  */ (this.bb!.readInt16(this.bb_pos + offset)) : NS7624605610262437867.org.apache.arrow.flatbuf.MetadataVersion.V1;
        }

        /**
         * @param org.apache.arrow.flatbuf.Schema= obj
         * @returns org.apache.arrow.flatbuf.Schema|null
         */
        schema(obj?: NS7624605610262437867.org.apache.arrow.flatbuf.Schema): NS7624605610262437867.org.apache.arrow.flatbuf.Schema | null {
            let offset = this.bb!.__offset(this.bb_pos, 6);
            return offset ? (obj || new NS7624605610262437867.org.apache.arrow.flatbuf.Schema).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
        }

        /**
         * @param number index
         * @param org.apache.arrow.flatbuf.Block= obj
         * @returns org.apache.arrow.flatbuf.Block
         */
        dictionaries(index: number, obj?: org.apache.arrow.flatbuf.Block): org.apache.arrow.flatbuf.Block | null {
            let offset = this.bb!.__offset(this.bb_pos, 8);
            return offset ? (obj || new org.apache.arrow.flatbuf.Block).__init(this.bb!.__vector(this.bb_pos + offset) + index * 24, this.bb!) : null;
        }

        /**
         * @returns number
         */
        dictionariesLength(): number {
            let offset = this.bb!.__offset(this.bb_pos, 8);
            return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
        }

        /**
         * @param number index
         * @param org.apache.arrow.flatbuf.Block= obj
         * @returns org.apache.arrow.flatbuf.Block
         */
        recordBatches(index: number, obj?: org.apache.arrow.flatbuf.Block): org.apache.arrow.flatbuf.Block | null {
            let offset = this.bb!.__offset(this.bb_pos, 10);
            return offset ? (obj || new org.apache.arrow.flatbuf.Block).__init(this.bb!.__vector(this.bb_pos + offset) + index * 24, this.bb!) : null;
        }

        /**
         * @returns number
         */
        recordBatchesLength(): number {
            let offset = this.bb!.__offset(this.bb_pos, 10);
            return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startFooter(builder: flatbuffers.Builder) {
            builder.startObject(4);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param org.apache.arrow.flatbuf.MetadataVersion version
         */
        static addVersion(builder: flatbuffers.Builder, version: NS7624605610262437867.org.apache.arrow.flatbuf.MetadataVersion) {
            builder.addFieldInt16(0, version, NS7624605610262437867.org.apache.arrow.flatbuf.MetadataVersion.V1);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset schemaOffset
         */
        static addSchema(builder: flatbuffers.Builder, schemaOffset: flatbuffers.Offset) {
            builder.addFieldOffset(1, schemaOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset dictionariesOffset
         */
        static addDictionaries(builder: flatbuffers.Builder, dictionariesOffset: flatbuffers.Offset) {
            builder.addFieldOffset(2, dictionariesOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number numElems
         */
        static startDictionariesVector(builder: flatbuffers.Builder, numElems: number) {
            builder.startVector(24, numElems, 8);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset recordBatchesOffset
         */
        static addRecordBatches(builder: flatbuffers.Builder, recordBatchesOffset: flatbuffers.Offset) {
            builder.addFieldOffset(3, recordBatchesOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number numElems
         */
        static startRecordBatchesVector(builder: flatbuffers.Builder, numElems: number) {
            builder.startVector(24, numElems, 8);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endFooter(builder: flatbuffers.Builder): flatbuffers.Offset {
            let offset = builder.endObject();
            return offset;
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset offset
         */
        static finishFooterBuffer(builder: flatbuffers.Builder, offset: flatbuffers.Offset) {
            builder.finish(offset);
        }

        static createFooter(builder: flatbuffers.Builder, version: NS7624605610262437867.org.apache.arrow.flatbuf.MetadataVersion, schemaOffset: flatbuffers.Offset, dictionariesOffset: flatbuffers.Offset, recordBatchesOffset: flatbuffers.Offset): flatbuffers.Offset {
            Footer.startFooter(builder);
            Footer.addVersion(builder, version);
            Footer.addSchema(builder, schemaOffset);
            Footer.addDictionaries(builder, dictionariesOffset);
            Footer.addRecordBatches(builder, recordBatchesOffset);
            return Footer.endFooter(builder);
        }
    }
}
/**
 * @constructor
 */
export namespace org.apache.arrow.flatbuf {
    export class Block {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns Block
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): Block {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * Index to the start of the RecordBlock (note this is past the Message header)
         *
         * @returns flatbuffers.Long
         */
        offset(): flatbuffers.Long {
            return this.bb!.readInt64(this.bb_pos);
        }

        /**
         * Length of the metadata
         *
         * @returns number
         */
        metaDataLength(): number {
            return this.bb!.readInt32(this.bb_pos + 8);
        }

        /**
         * Length of the data (this is aligned so there can be a gap between this and
         * the metatdata).
         *
         * @returns flatbuffers.Long
         */
        bodyLength(): flatbuffers.Long {
            return this.bb!.readInt64(this.bb_pos + 16);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Long offset
         * @param number metaDataLength
         * @param flatbuffers.Long bodyLength
         * @returns flatbuffers.Offset
         */
        static createBlock(builder: flatbuffers.Builder, offset: flatbuffers.Long, metaDataLength: number, bodyLength: flatbuffers.Long): flatbuffers.Offset {
            builder.prep(8, 24);
            builder.writeInt64(bodyLength);
            builder.pad(4);
            builder.writeInt32(metaDataLength);
            builder.writeInt64(offset);
            return builder.offset();
        }

    }
}
