/* tslint:disable:class-name */

// automatically generated by the FlatBuffers compiler, do not modify

import { flatbuffers } from 'flatbuffers';
/**
 * @enum {number}
 */
export namespace org.apache.arrow.flatbuf {
    export enum MetadataVersion {
        /**
         * 0.1.0
         */
        V1 = 0,

        /**
         * 0.2.0
         */
        V2 = 1,

        /**
         * 0.3.0 -> 0.7.1
         */
        V3 = 2,

        /**
         * >= 0.8.0
         */
        V4 = 3
    }
}

/**
 * @enum {number}
 */
export namespace org.apache.arrow.flatbuf {
    export enum UnionMode {
        Sparse = 0,
        Dense = 1
    }
}

/**
 * @enum {number}
 */
export namespace org.apache.arrow.flatbuf {
    export enum Precision {
        HALF = 0,
        SINGLE = 1,
        DOUBLE = 2
    }
}

/**
 * @enum {number}
 */
export namespace org.apache.arrow.flatbuf {
    export enum DateUnit {
        DAY = 0,
        MILLISECOND = 1
    }
}

/**
 * @enum {number}
 */
export namespace org.apache.arrow.flatbuf {
    export enum TimeUnit {
        SECOND = 0,
        MILLISECOND = 1,
        MICROSECOND = 2,
        NANOSECOND = 3
    }
}

/**
 * @enum {number}
 */
export namespace org.apache.arrow.flatbuf {
    export enum IntervalUnit {
        YEAR_MONTH = 0,
        DAY_TIME = 1
    }
}

/**
 * ----------------------------------------------------------------------
 * Top-level Type value, enabling extensible type-specific metadata. We can
 * add new logical types to Type without breaking backwards compatibility
 *
 * @enum {number}
 */
export namespace org.apache.arrow.flatbuf {
    export enum Type {
        NONE = 0,
        Null = 1,
        Int = 2,
        FloatingPoint = 3,
        Binary = 4,
        Utf8 = 5,
        Bool = 6,
        Decimal = 7,
        Date = 8,
        Time = 9,
        Timestamp = 10,
        Interval = 11,
        List = 12,
        Struct_ = 13,
        Union = 14,
        FixedSizeBinary = 15,
        FixedSizeList = 16,
        Map = 17,
        Duration = 18,
        LargeBinary = 19,
        LargeUtf8 = 20,
        LargeList = 21
    }
}

/**
 * ----------------------------------------------------------------------
 * Endianness of the platform producing the data
 *
 * @enum {number}
 */
export namespace org.apache.arrow.flatbuf {
    export enum Endianness {
        Little = 0,
        Big = 1
    }
}

/**
 * These are stored in the flatbuffer in the Type union below
 *
 * @constructor
 */
export namespace org.apache.arrow.flatbuf {
    export class Null {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns Null
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): Null {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param Null= obj
         * @returns Null
         */
        static getRootAsNull(bb: flatbuffers.ByteBuffer, obj?: Null): Null {
            return (obj || new Null).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startNull(builder: flatbuffers.Builder) {
            builder.startObject(0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endNull(builder: flatbuffers.Builder): flatbuffers.Offset {
            let offset = builder.endObject();
            return offset;
        }

        static createNull(builder: flatbuffers.Builder): flatbuffers.Offset {
            Null.startNull(builder);
            return Null.endNull(builder);
        }
    }
}
/**
 * A Struct_ in the flatbuffer metadata is the same as an Arrow Struct
 * (according to the physical memory layout). We used Struct_ here as
 * Struct is a reserved word in Flatbuffers
 *
 * @constructor
 */
export namespace org.apache.arrow.flatbuf {
    export class Struct_ {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns Struct_
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): Struct_ {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param Struct_= obj
         * @returns Struct_
         */
        static getRootAsStruct_(bb: flatbuffers.ByteBuffer, obj?: Struct_): Struct_ {
            return (obj || new Struct_).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startStruct_(builder: flatbuffers.Builder) {
            builder.startObject(0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endStruct_(builder: flatbuffers.Builder): flatbuffers.Offset {
            let offset = builder.endObject();
            return offset;
        }

        static createStruct_(builder: flatbuffers.Builder): flatbuffers.Offset {
            Struct_.startStruct_(builder);
            return Struct_.endStruct_(builder);
        }
    }
}
/**
 * @constructor
 */
export namespace org.apache.arrow.flatbuf {
    export class List {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns List
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): List {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param List= obj
         * @returns List
         */
        static getRootAsList(bb: flatbuffers.ByteBuffer, obj?: List): List {
            return (obj || new List).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startList(builder: flatbuffers.Builder) {
            builder.startObject(0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endList(builder: flatbuffers.Builder): flatbuffers.Offset {
            let offset = builder.endObject();
            return offset;
        }

        static createList(builder: flatbuffers.Builder): flatbuffers.Offset {
            List.startList(builder);
            return List.endList(builder);
        }
    }
}
/**
 * Same as List, but with 64-bit offsets, allowing to represent
 * extremely large data values.
 *
 * @constructor
 */
export namespace org.apache.arrow.flatbuf {
    export class LargeList {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns LargeList
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): LargeList {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param LargeList= obj
         * @returns LargeList
         */
        static getRootAsLargeList(bb: flatbuffers.ByteBuffer, obj?: LargeList): LargeList {
            return (obj || new LargeList).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startLargeList(builder: flatbuffers.Builder) {
            builder.startObject(0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endLargeList(builder: flatbuffers.Builder): flatbuffers.Offset {
            let offset = builder.endObject();
            return offset;
        }

        static createLargeList(builder: flatbuffers.Builder): flatbuffers.Offset {
            LargeList.startLargeList(builder);
            return LargeList.endLargeList(builder);
        }
    }
}
/**
 * @constructor
 */
export namespace org.apache.arrow.flatbuf {
    export class FixedSizeList {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns FixedSizeList
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): FixedSizeList {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param FixedSizeList= obj
         * @returns FixedSizeList
         */
        static getRootAsFixedSizeList(bb: flatbuffers.ByteBuffer, obj?: FixedSizeList): FixedSizeList {
            return (obj || new FixedSizeList).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * Number of list items per value
         *
         * @returns number
         */
        listSize(): number {
            let offset = this.bb!.__offset(this.bb_pos, 4);
            return offset ? this.bb!.readInt32(this.bb_pos + offset) : 0;
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startFixedSizeList(builder: flatbuffers.Builder) {
            builder.startObject(1);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number listSize
         */
        static addListSize(builder: flatbuffers.Builder, listSize: number) {
            builder.addFieldInt32(0, listSize, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endFixedSizeList(builder: flatbuffers.Builder): flatbuffers.Offset {
            let offset = builder.endObject();
            return offset;
        }

        static createFixedSizeList(builder: flatbuffers.Builder, listSize: number): flatbuffers.Offset {
            FixedSizeList.startFixedSizeList(builder);
            FixedSizeList.addListSize(builder, listSize);
            return FixedSizeList.endFixedSizeList(builder);
        }
    }
}
/**
 * A Map is a logical nested type that is represented as
 *
 * List<entry: Struct<key: K, value: V>>
 *
 * In this layout, the keys and values are each respectively contiguous. We do
 * not constrain the key and value types, so the application is responsible
 * for ensuring that the keys are hashable and unique. Whether the keys are sorted
 * may be set in the metadata for this field
 *
 * In a Field with Map type, the Field has a child Struct field, which then
 * has two children: key type and the second the value type. The names of the
 * child fields may be respectively "entry", "key", and "value", but this is
 * not enforced
 *
 * Map
 *   - child[0] entry: Struct
 *     - child[0] key: K
 *     - child[1] value: V
 *
 * Neither the "entry" field nor the "key" field may be nullable.
 *
 * The metadata is structured so that Arrow systems without special handling
 * for Map can make Map an alias for List. The "layout" attribute for the Map
 * field must have the same contents as a List.
 *
 * @constructor
 */
export namespace org.apache.arrow.flatbuf {
    export class Map {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns Map
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): Map {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param Map= obj
         * @returns Map
         */
        static getRootAsMap(bb: flatbuffers.ByteBuffer, obj?: Map): Map {
            return (obj || new Map).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * Set to true if the keys within each value are sorted
         *
         * @returns boolean
         */
        keysSorted(): boolean {
            let offset = this.bb!.__offset(this.bb_pos, 4);
            return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : false;
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startMap(builder: flatbuffers.Builder) {
            builder.startObject(1);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param boolean keysSorted
         */
        static addKeysSorted(builder: flatbuffers.Builder, keysSorted: boolean) {
            builder.addFieldInt8(0, +keysSorted, +false);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endMap(builder: flatbuffers.Builder): flatbuffers.Offset {
            let offset = builder.endObject();
            return offset;
        }

        static createMap(builder: flatbuffers.Builder, keysSorted: boolean): flatbuffers.Offset {
            Map.startMap(builder);
            Map.addKeysSorted(builder, keysSorted);
            return Map.endMap(builder);
        }
    }
}
/**
 * A union is a complex type with children in Field
 * By default ids in the type vector refer to the offsets in the children
 * optionally typeIds provides an indirection between the child offset and the type id
 * for each child typeIds[offset] is the id used in the type vector
 *
 * @constructor
 */
export namespace org.apache.arrow.flatbuf {
    export class Union {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns Union
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): Union {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param Union= obj
         * @returns Union
         */
        static getRootAsUnion(bb: flatbuffers.ByteBuffer, obj?: Union): Union {
            return (obj || new Union).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * @returns org.apache.arrow.flatbuf.UnionMode
         */
        mode(): org.apache.arrow.flatbuf.UnionMode {
            let offset = this.bb!.__offset(this.bb_pos, 4);
            return offset ? /**  */ (this.bb!.readInt16(this.bb_pos + offset)) : org.apache.arrow.flatbuf.UnionMode.Sparse;
        }

        /**
         * @param number index
         * @returns number
         */
        typeIds(index: number): number | null {
            let offset = this.bb!.__offset(this.bb_pos, 6);
            return offset ? this.bb!.readInt32(this.bb!.__vector(this.bb_pos + offset) + index * 4) : 0;
        }

        /**
         * @returns number
         */
        typeIdsLength(): number {
            let offset = this.bb!.__offset(this.bb_pos, 6);
            return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
        }

        /**
         * @returns Int32Array
         */
        typeIdsArray(): Int32Array | null {
            let offset = this.bb!.__offset(this.bb_pos, 6);
            return offset ? new Int32Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startUnion(builder: flatbuffers.Builder) {
            builder.startObject(2);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param org.apache.arrow.flatbuf.UnionMode mode
         */
        static addMode(builder: flatbuffers.Builder, mode: org.apache.arrow.flatbuf.UnionMode) {
            builder.addFieldInt16(0, mode, org.apache.arrow.flatbuf.UnionMode.Sparse);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset typeIdsOffset
         */
        static addTypeIds(builder: flatbuffers.Builder, typeIdsOffset: flatbuffers.Offset) {
            builder.addFieldOffset(1, typeIdsOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param Array.<number> data
         * @returns flatbuffers.Offset
         */
        static createTypeIdsVector(builder: flatbuffers.Builder, data: number[] | Int32Array): flatbuffers.Offset {
            builder.startVector(4, data.length, 4);
            for (let i = data.length - 1; i >= 0; i--) {
                builder.addInt32(data[i]);
            }
            return builder.endVector();
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number numElems
         */
        static startTypeIdsVector(builder: flatbuffers.Builder, numElems: number) {
            builder.startVector(4, numElems, 4);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endUnion(builder: flatbuffers.Builder): flatbuffers.Offset {
            let offset = builder.endObject();
            return offset;
        }

        static createUnion(builder: flatbuffers.Builder, mode: org.apache.arrow.flatbuf.UnionMode, typeIdsOffset: flatbuffers.Offset): flatbuffers.Offset {
            Union.startUnion(builder);
            Union.addMode(builder, mode);
            Union.addTypeIds(builder, typeIdsOffset);
            return Union.endUnion(builder);
        }
    }
}
/**
 * @constructor
 */
export namespace org.apache.arrow.flatbuf {
    export class Int {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns Int
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): Int {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param Int= obj
         * @returns Int
         */
        static getRootAsInt(bb: flatbuffers.ByteBuffer, obj?: Int): Int {
            return (obj || new Int).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * @returns number
         */
        bitWidth(): number {
            let offset = this.bb!.__offset(this.bb_pos, 4);
            return offset ? this.bb!.readInt32(this.bb_pos + offset) : 0;
        }

        /**
         * @returns boolean
         */
        isSigned(): boolean {
            let offset = this.bb!.__offset(this.bb_pos, 6);
            return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : false;
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startInt(builder: flatbuffers.Builder) {
            builder.startObject(2);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number bitWidth
         */
        static addBitWidth(builder: flatbuffers.Builder, bitWidth: number) {
            builder.addFieldInt32(0, bitWidth, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param boolean isSigned
         */
        static addIsSigned(builder: flatbuffers.Builder, isSigned: boolean) {
            builder.addFieldInt8(1, +isSigned, +false);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endInt(builder: flatbuffers.Builder): flatbuffers.Offset {
            let offset = builder.endObject();
            return offset;
        }

        static createInt(builder: flatbuffers.Builder, bitWidth: number, isSigned: boolean): flatbuffers.Offset {
            Int.startInt(builder);
            Int.addBitWidth(builder, bitWidth);
            Int.addIsSigned(builder, isSigned);
            return Int.endInt(builder);
        }
    }
}
/**
 * @constructor
 */
export namespace org.apache.arrow.flatbuf {
    export class FloatingPoint {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns FloatingPoint
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): FloatingPoint {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param FloatingPoint= obj
         * @returns FloatingPoint
         */
        static getRootAsFloatingPoint(bb: flatbuffers.ByteBuffer, obj?: FloatingPoint): FloatingPoint {
            return (obj || new FloatingPoint).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * @returns org.apache.arrow.flatbuf.Precision
         */
        precision(): org.apache.arrow.flatbuf.Precision {
            let offset = this.bb!.__offset(this.bb_pos, 4);
            return offset ? /**  */ (this.bb!.readInt16(this.bb_pos + offset)) : org.apache.arrow.flatbuf.Precision.HALF;
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startFloatingPoint(builder: flatbuffers.Builder) {
            builder.startObject(1);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param org.apache.arrow.flatbuf.Precision precision
         */
        static addPrecision(builder: flatbuffers.Builder, precision: org.apache.arrow.flatbuf.Precision) {
            builder.addFieldInt16(0, precision, org.apache.arrow.flatbuf.Precision.HALF);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endFloatingPoint(builder: flatbuffers.Builder): flatbuffers.Offset {
            let offset = builder.endObject();
            return offset;
        }

        static createFloatingPoint(builder: flatbuffers.Builder, precision: org.apache.arrow.flatbuf.Precision): flatbuffers.Offset {
            FloatingPoint.startFloatingPoint(builder);
            FloatingPoint.addPrecision(builder, precision);
            return FloatingPoint.endFloatingPoint(builder);
        }
    }
}
/**
 * Unicode with UTF-8 encoding
 *
 * @constructor
 */
export namespace org.apache.arrow.flatbuf {
    export class Utf8 {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns Utf8
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): Utf8 {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param Utf8= obj
         * @returns Utf8
         */
        static getRootAsUtf8(bb: flatbuffers.ByteBuffer, obj?: Utf8): Utf8 {
            return (obj || new Utf8).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startUtf8(builder: flatbuffers.Builder) {
            builder.startObject(0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endUtf8(builder: flatbuffers.Builder): flatbuffers.Offset {
            let offset = builder.endObject();
            return offset;
        }

        static createUtf8(builder: flatbuffers.Builder): flatbuffers.Offset {
            Utf8.startUtf8(builder);
            return Utf8.endUtf8(builder);
        }
    }
}
/**
 * Opaque binary data
 *
 * @constructor
 */
export namespace org.apache.arrow.flatbuf {
    export class Binary {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns Binary
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): Binary {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param Binary= obj
         * @returns Binary
         */
        static getRootAsBinary(bb: flatbuffers.ByteBuffer, obj?: Binary): Binary {
            return (obj || new Binary).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startBinary(builder: flatbuffers.Builder) {
            builder.startObject(0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endBinary(builder: flatbuffers.Builder): flatbuffers.Offset {
            let offset = builder.endObject();
            return offset;
        }

        static createBinary(builder: flatbuffers.Builder): flatbuffers.Offset {
            Binary.startBinary(builder);
            return Binary.endBinary(builder);
        }
    }
}
/**
 * Same as Utf8, but with 64-bit offsets, allowing to represent
 * extremely large data values.
 *
 * @constructor
 */
export namespace org.apache.arrow.flatbuf {
    export class LargeUtf8 {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns LargeUtf8
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): LargeUtf8 {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param LargeUtf8= obj
         * @returns LargeUtf8
         */
        static getRootAsLargeUtf8(bb: flatbuffers.ByteBuffer, obj?: LargeUtf8): LargeUtf8 {
            return (obj || new LargeUtf8).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startLargeUtf8(builder: flatbuffers.Builder) {
            builder.startObject(0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endLargeUtf8(builder: flatbuffers.Builder): flatbuffers.Offset {
            let offset = builder.endObject();
            return offset;
        }

        static createLargeUtf8(builder: flatbuffers.Builder): flatbuffers.Offset {
            LargeUtf8.startLargeUtf8(builder);
            return LargeUtf8.endLargeUtf8(builder);
        }
    }
}
/**
 * Same as Binary, but with 64-bit offsets, allowing to represent
 * extremely large data values.
 *
 * @constructor
 */
export namespace org.apache.arrow.flatbuf {
    export class LargeBinary {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns LargeBinary
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): LargeBinary {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param LargeBinary= obj
         * @returns LargeBinary
         */
        static getRootAsLargeBinary(bb: flatbuffers.ByteBuffer, obj?: LargeBinary): LargeBinary {
            return (obj || new LargeBinary).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startLargeBinary(builder: flatbuffers.Builder) {
            builder.startObject(0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endLargeBinary(builder: flatbuffers.Builder): flatbuffers.Offset {
            let offset = builder.endObject();
            return offset;
        }

        static createLargeBinary(builder: flatbuffers.Builder): flatbuffers.Offset {
            LargeBinary.startLargeBinary(builder);
            return LargeBinary.endLargeBinary(builder);
        }
    }
}
/**
 * @constructor
 */
export namespace org.apache.arrow.flatbuf {
    export class FixedSizeBinary {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns FixedSizeBinary
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): FixedSizeBinary {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param FixedSizeBinary= obj
         * @returns FixedSizeBinary
         */
        static getRootAsFixedSizeBinary(bb: flatbuffers.ByteBuffer, obj?: FixedSizeBinary): FixedSizeBinary {
            return (obj || new FixedSizeBinary).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * Number of bytes per value
         *
         * @returns number
         */
        byteWidth(): number {
            let offset = this.bb!.__offset(this.bb_pos, 4);
            return offset ? this.bb!.readInt32(this.bb_pos + offset) : 0;
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startFixedSizeBinary(builder: flatbuffers.Builder) {
            builder.startObject(1);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number byteWidth
         */
        static addByteWidth(builder: flatbuffers.Builder, byteWidth: number) {
            builder.addFieldInt32(0, byteWidth, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endFixedSizeBinary(builder: flatbuffers.Builder): flatbuffers.Offset {
            let offset = builder.endObject();
            return offset;
        }

        static createFixedSizeBinary(builder: flatbuffers.Builder, byteWidth: number): flatbuffers.Offset {
            FixedSizeBinary.startFixedSizeBinary(builder);
            FixedSizeBinary.addByteWidth(builder, byteWidth);
            return FixedSizeBinary.endFixedSizeBinary(builder);
        }
    }
}
/**
 * @constructor
 */
export namespace org.apache.arrow.flatbuf {
    export class Bool {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns Bool
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): Bool {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param Bool= obj
         * @returns Bool
         */
        static getRootAsBool(bb: flatbuffers.ByteBuffer, obj?: Bool): Bool {
            return (obj || new Bool).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startBool(builder: flatbuffers.Builder) {
            builder.startObject(0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endBool(builder: flatbuffers.Builder): flatbuffers.Offset {
            let offset = builder.endObject();
            return offset;
        }

        static createBool(builder: flatbuffers.Builder): flatbuffers.Offset {
            Bool.startBool(builder);
            return Bool.endBool(builder);
        }
    }
}
/**
 * @constructor
 */
export namespace org.apache.arrow.flatbuf {
    export class Decimal {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns Decimal
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): Decimal {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param Decimal= obj
         * @returns Decimal
         */
        static getRootAsDecimal(bb: flatbuffers.ByteBuffer, obj?: Decimal): Decimal {
            return (obj || new Decimal).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * Total number of decimal digits
         *
         * @returns number
         */
        precision(): number {
            let offset = this.bb!.__offset(this.bb_pos, 4);
            return offset ? this.bb!.readInt32(this.bb_pos + offset) : 0;
        }

        /**
         * Number of digits after the decimal point "."
         *
         * @returns number
         */
        scale(): number {
            let offset = this.bb!.__offset(this.bb_pos, 6);
            return offset ? this.bb!.readInt32(this.bb_pos + offset) : 0;
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startDecimal(builder: flatbuffers.Builder) {
            builder.startObject(2);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number precision
         */
        static addPrecision(builder: flatbuffers.Builder, precision: number) {
            builder.addFieldInt32(0, precision, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number scale
         */
        static addScale(builder: flatbuffers.Builder, scale: number) {
            builder.addFieldInt32(1, scale, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endDecimal(builder: flatbuffers.Builder): flatbuffers.Offset {
            let offset = builder.endObject();
            return offset;
        }

        static createDecimal(builder: flatbuffers.Builder, precision: number, scale: number): flatbuffers.Offset {
            Decimal.startDecimal(builder);
            Decimal.addPrecision(builder, precision);
            Decimal.addScale(builder, scale);
            return Decimal.endDecimal(builder);
        }
    }
}
/**
 * Date is either a 32-bit or 64-bit type representing elapsed time since UNIX
 * epoch (1970-01-01), stored in either of two units:
 *
 * * Milliseconds (64 bits) indicating UNIX time elapsed since the epoch (no
 *   leap seconds), where the values are evenly divisible by 86400000
 * * Days (32 bits) since the UNIX epoch
 *
 * @constructor
 */
export namespace org.apache.arrow.flatbuf {
    export class Date {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns Date
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): Date {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param Date= obj
         * @returns Date
         */
        static getRootAsDate(bb: flatbuffers.ByteBuffer, obj?: Date): Date {
            return (obj || new Date).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * @returns org.apache.arrow.flatbuf.DateUnit
         */
        unit(): org.apache.arrow.flatbuf.DateUnit {
            let offset = this.bb!.__offset(this.bb_pos, 4);
            return offset ? /**  */ (this.bb!.readInt16(this.bb_pos + offset)) : org.apache.arrow.flatbuf.DateUnit.MILLISECOND;
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startDate(builder: flatbuffers.Builder) {
            builder.startObject(1);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param org.apache.arrow.flatbuf.DateUnit unit
         */
        static addUnit(builder: flatbuffers.Builder, unit: org.apache.arrow.flatbuf.DateUnit) {
            builder.addFieldInt16(0, unit, org.apache.arrow.flatbuf.DateUnit.MILLISECOND);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endDate(builder: flatbuffers.Builder): flatbuffers.Offset {
            let offset = builder.endObject();
            return offset;
        }

        static createDate(builder: flatbuffers.Builder, unit: org.apache.arrow.flatbuf.DateUnit): flatbuffers.Offset {
            Date.startDate(builder);
            Date.addUnit(builder, unit);
            return Date.endDate(builder);
        }
    }
}
/**
 * Time type. The physical storage type depends on the unit
 * - SECOND and MILLISECOND: 32 bits
 * - MICROSECOND and NANOSECOND: 64 bits
 *
 * @constructor
 */
export namespace org.apache.arrow.flatbuf {
    export class Time {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns Time
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): Time {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param Time= obj
         * @returns Time
         */
        static getRootAsTime(bb: flatbuffers.ByteBuffer, obj?: Time): Time {
            return (obj || new Time).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * @returns org.apache.arrow.flatbuf.TimeUnit
         */
        unit(): org.apache.arrow.flatbuf.TimeUnit {
            let offset = this.bb!.__offset(this.bb_pos, 4);
            return offset ? /**  */ (this.bb!.readInt16(this.bb_pos + offset)) : org.apache.arrow.flatbuf.TimeUnit.MILLISECOND;
        }

        /**
         * @returns number
         */
        bitWidth(): number {
            let offset = this.bb!.__offset(this.bb_pos, 6);
            return offset ? this.bb!.readInt32(this.bb_pos + offset) : 32;
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startTime(builder: flatbuffers.Builder) {
            builder.startObject(2);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param org.apache.arrow.flatbuf.TimeUnit unit
         */
        static addUnit(builder: flatbuffers.Builder, unit: org.apache.arrow.flatbuf.TimeUnit) {
            builder.addFieldInt16(0, unit, org.apache.arrow.flatbuf.TimeUnit.MILLISECOND);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number bitWidth
         */
        static addBitWidth(builder: flatbuffers.Builder, bitWidth: number) {
            builder.addFieldInt32(1, bitWidth, 32);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endTime(builder: flatbuffers.Builder): flatbuffers.Offset {
            let offset = builder.endObject();
            return offset;
        }

        static createTime(builder: flatbuffers.Builder, unit: org.apache.arrow.flatbuf.TimeUnit, bitWidth: number): flatbuffers.Offset {
            Time.startTime(builder);
            Time.addUnit(builder, unit);
            Time.addBitWidth(builder, bitWidth);
            return Time.endTime(builder);
        }
    }
}
/**
 * Time elapsed from the Unix epoch, 00:00:00.000 on 1 January 1970, excluding
 * leap seconds, as a 64-bit integer. Note that UNIX time does not include
 * leap seconds.
 *
 * The Timestamp metadata supports both "time zone naive" and "time zone
 * aware" timestamps. Read about the timezone attribute for more detail
 *
 * @constructor
 */
export namespace org.apache.arrow.flatbuf {
    export class Timestamp {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns Timestamp
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): Timestamp {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param Timestamp= obj
         * @returns Timestamp
         */
        static getRootAsTimestamp(bb: flatbuffers.ByteBuffer, obj?: Timestamp): Timestamp {
            return (obj || new Timestamp).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * @returns org.apache.arrow.flatbuf.TimeUnit
         */
        unit(): org.apache.arrow.flatbuf.TimeUnit {
            let offset = this.bb!.__offset(this.bb_pos, 4);
            return offset ? /**  */ (this.bb!.readInt16(this.bb_pos + offset)) : org.apache.arrow.flatbuf.TimeUnit.SECOND;
        }

        /**
         * The time zone is a string indicating the name of a time zone, one of:
         *
         * * As used in the Olson time zone database (the "tz database" or
         *   "tzdata"), such as "America/New_York"
         * * An absolute time zone offset of the form +XX:XX or -XX:XX, such as +07:30
         *
         * Whether a timezone string is present indicates different semantics about
         * the data:
         *
         * * If the time zone is null or equal to an empty string, the data is "time
         *   zone naive" and shall be displayed *as is* to the user, not localized
         *   to the locale of the user. This data can be though of as UTC but
         *   without having "UTC" as the time zone, it is not considered to be
         *   localized to any time zone
         *
         * * If the time zone is set to a valid value, values can be displayed as
         *   "localized" to that time zone, even though the underlying 64-bit
         *   integers are identical to the same data stored in UTC. Converting
         *   between time zones is a metadata-only operation and does not change the
         *   underlying values
         *
         * @param flatbuffers.Encoding= optionalEncoding
         * @returns string|Uint8Array|null
         */
        timezone(): string | null;
        timezone(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
        timezone(optionalEncoding?: any): string | Uint8Array | null {
            let offset = this.bb!.__offset(this.bb_pos, 6);
            return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startTimestamp(builder: flatbuffers.Builder) {
            builder.startObject(2);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param org.apache.arrow.flatbuf.TimeUnit unit
         */
        static addUnit(builder: flatbuffers.Builder, unit: org.apache.arrow.flatbuf.TimeUnit) {
            builder.addFieldInt16(0, unit, org.apache.arrow.flatbuf.TimeUnit.SECOND);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset timezoneOffset
         */
        static addTimezone(builder: flatbuffers.Builder, timezoneOffset: flatbuffers.Offset) {
            builder.addFieldOffset(1, timezoneOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endTimestamp(builder: flatbuffers.Builder): flatbuffers.Offset {
            let offset = builder.endObject();
            return offset;
        }

        static createTimestamp(builder: flatbuffers.Builder, unit: org.apache.arrow.flatbuf.TimeUnit, timezoneOffset: flatbuffers.Offset): flatbuffers.Offset {
            Timestamp.startTimestamp(builder);
            Timestamp.addUnit(builder, unit);
            Timestamp.addTimezone(builder, timezoneOffset);
            return Timestamp.endTimestamp(builder);
        }
    }
}
/**
 * @constructor
 */
export namespace org.apache.arrow.flatbuf {
    export class Interval {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns Interval
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): Interval {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param Interval= obj
         * @returns Interval
         */
        static getRootAsInterval(bb: flatbuffers.ByteBuffer, obj?: Interval): Interval {
            return (obj || new Interval).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * @returns org.apache.arrow.flatbuf.IntervalUnit
         */
        unit(): org.apache.arrow.flatbuf.IntervalUnit {
            let offset = this.bb!.__offset(this.bb_pos, 4);
            return offset ? /**  */ (this.bb!.readInt16(this.bb_pos + offset)) : org.apache.arrow.flatbuf.IntervalUnit.YEAR_MONTH;
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startInterval(builder: flatbuffers.Builder) {
            builder.startObject(1);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param org.apache.arrow.flatbuf.IntervalUnit unit
         */
        static addUnit(builder: flatbuffers.Builder, unit: org.apache.arrow.flatbuf.IntervalUnit) {
            builder.addFieldInt16(0, unit, org.apache.arrow.flatbuf.IntervalUnit.YEAR_MONTH);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endInterval(builder: flatbuffers.Builder): flatbuffers.Offset {
            let offset = builder.endObject();
            return offset;
        }

        static createInterval(builder: flatbuffers.Builder, unit: org.apache.arrow.flatbuf.IntervalUnit): flatbuffers.Offset {
            Interval.startInterval(builder);
            Interval.addUnit(builder, unit);
            return Interval.endInterval(builder);
        }
    }
}
/**
 * @constructor
 */
export namespace org.apache.arrow.flatbuf {
    export class Duration {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns Duration
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): Duration {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param Duration= obj
         * @returns Duration
         */
        static getRootAsDuration(bb: flatbuffers.ByteBuffer, obj?: Duration): Duration {
            return (obj || new Duration).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * @returns org.apache.arrow.flatbuf.TimeUnit
         */
        unit(): org.apache.arrow.flatbuf.TimeUnit {
            let offset = this.bb!.__offset(this.bb_pos, 4);
            return offset ? /**  */ (this.bb!.readInt16(this.bb_pos + offset)) : org.apache.arrow.flatbuf.TimeUnit.MILLISECOND;
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startDuration(builder: flatbuffers.Builder) {
            builder.startObject(1);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param org.apache.arrow.flatbuf.TimeUnit unit
         */
        static addUnit(builder: flatbuffers.Builder, unit: org.apache.arrow.flatbuf.TimeUnit) {
            builder.addFieldInt16(0, unit, org.apache.arrow.flatbuf.TimeUnit.MILLISECOND);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endDuration(builder: flatbuffers.Builder): flatbuffers.Offset {
            let offset = builder.endObject();
            return offset;
        }

        static createDuration(builder: flatbuffers.Builder, unit: org.apache.arrow.flatbuf.TimeUnit): flatbuffers.Offset {
            Duration.startDuration(builder);
            Duration.addUnit(builder, unit);
            return Duration.endDuration(builder);
        }
    }
}
/**
 * ----------------------------------------------------------------------
 * user defined key value pairs to add custom metadata to arrow
 * key namespacing is the responsibility of the user
 *
 * @constructor
 */
export namespace org.apache.arrow.flatbuf {
    export class KeyValue {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns KeyValue
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): KeyValue {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param KeyValue= obj
         * @returns KeyValue
         */
        static getRootAsKeyValue(bb: flatbuffers.ByteBuffer, obj?: KeyValue): KeyValue {
            return (obj || new KeyValue).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * @param flatbuffers.Encoding= optionalEncoding
         * @returns string|Uint8Array|null
         */
        key(): string | null;
        key(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
        key(optionalEncoding?: any): string | Uint8Array | null {
            let offset = this.bb!.__offset(this.bb_pos, 4);
            return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
        }

        /**
         * @param flatbuffers.Encoding= optionalEncoding
         * @returns string|Uint8Array|null
         */
        value(): string | null;
        value(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
        value(optionalEncoding?: any): string | Uint8Array | null {
            let offset = this.bb!.__offset(this.bb_pos, 6);
            return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startKeyValue(builder: flatbuffers.Builder) {
            builder.startObject(2);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset keyOffset
         */
        static addKey(builder: flatbuffers.Builder, keyOffset: flatbuffers.Offset) {
            builder.addFieldOffset(0, keyOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset valueOffset
         */
        static addValue(builder: flatbuffers.Builder, valueOffset: flatbuffers.Offset) {
            builder.addFieldOffset(1, valueOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endKeyValue(builder: flatbuffers.Builder): flatbuffers.Offset {
            let offset = builder.endObject();
            return offset;
        }

        static createKeyValue(builder: flatbuffers.Builder, keyOffset: flatbuffers.Offset, valueOffset: flatbuffers.Offset): flatbuffers.Offset {
            KeyValue.startKeyValue(builder);
            KeyValue.addKey(builder, keyOffset);
            KeyValue.addValue(builder, valueOffset);
            return KeyValue.endKeyValue(builder);
        }
    }
}
/**
 * ----------------------------------------------------------------------
 * Dictionary encoding metadata
 *
 * @constructor
 */
export namespace org.apache.arrow.flatbuf {
    export class DictionaryEncoding {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns DictionaryEncoding
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): DictionaryEncoding {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param DictionaryEncoding= obj
         * @returns DictionaryEncoding
         */
        static getRootAsDictionaryEncoding(bb: flatbuffers.ByteBuffer, obj?: DictionaryEncoding): DictionaryEncoding {
            return (obj || new DictionaryEncoding).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * The known dictionary id in the application where this data is used. In
         * the file or streaming formats, the dictionary ids are found in the
         * DictionaryBatch messages
         *
         * @returns flatbuffers.Long
         */
        id(): flatbuffers.Long {
            let offset = this.bb!.__offset(this.bb_pos, 4);
            return offset ? this.bb!.readInt64(this.bb_pos + offset) : this.bb!.createLong(0, 0);
        }

        /**
         * The dictionary indices are constrained to be positive integers. If this
         * field is null, the indices must be signed int32
         *
         * @param org.apache.arrow.flatbuf.Int= obj
         * @returns org.apache.arrow.flatbuf.Int|null
         */
        indexType(obj?: org.apache.arrow.flatbuf.Int): org.apache.arrow.flatbuf.Int | null {
            let offset = this.bb!.__offset(this.bb_pos, 6);
            return offset ? (obj || new org.apache.arrow.flatbuf.Int).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
        }

        /**
         * By default, dictionaries are not ordered, or the order does not have
         * semantic meaning. In some statistical, applications, dictionary-encoding
         * is used to represent ordered categorical data, and we provide a way to
         * preserve that metadata here
         *
         * @returns boolean
         */
        isOrdered(): boolean {
            let offset = this.bb!.__offset(this.bb_pos, 8);
            return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : false;
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startDictionaryEncoding(builder: flatbuffers.Builder) {
            builder.startObject(3);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Long id
         */
        static addId(builder: flatbuffers.Builder, id: flatbuffers.Long) {
            builder.addFieldInt64(0, id, builder.createLong(0, 0));
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset indexTypeOffset
         */
        static addIndexType(builder: flatbuffers.Builder, indexTypeOffset: flatbuffers.Offset) {
            builder.addFieldOffset(1, indexTypeOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param boolean isOrdered
         */
        static addIsOrdered(builder: flatbuffers.Builder, isOrdered: boolean) {
            builder.addFieldInt8(2, +isOrdered, +false);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endDictionaryEncoding(builder: flatbuffers.Builder): flatbuffers.Offset {
            let offset = builder.endObject();
            return offset;
        }

        static createDictionaryEncoding(builder: flatbuffers.Builder, id: flatbuffers.Long, indexTypeOffset: flatbuffers.Offset, isOrdered: boolean): flatbuffers.Offset {
            DictionaryEncoding.startDictionaryEncoding(builder);
            DictionaryEncoding.addId(builder, id);
            DictionaryEncoding.addIndexType(builder, indexTypeOffset);
            DictionaryEncoding.addIsOrdered(builder, isOrdered);
            return DictionaryEncoding.endDictionaryEncoding(builder);
        }
    }
}
/**
 * ----------------------------------------------------------------------
 * A field represents a named column in a record / row batch or child of a
 * nested type.
 *
 * @constructor
 */
export namespace org.apache.arrow.flatbuf {
    export class Field {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns Field
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): Field {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param Field= obj
         * @returns Field
         */
        static getRootAsField(bb: flatbuffers.ByteBuffer, obj?: Field): Field {
            return (obj || new Field).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * Name is not required, in i.e. a List
         *
         * @param flatbuffers.Encoding= optionalEncoding
         * @returns string|Uint8Array|null
         */
        name(): string | null;
        name(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
        name(optionalEncoding?: any): string | Uint8Array | null {
            let offset = this.bb!.__offset(this.bb_pos, 4);
            return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
        }

        /**
         * Whether or not this field can contain nulls. Should be true in general.
         *
         * @returns boolean
         */
        nullable(): boolean {
            let offset = this.bb!.__offset(this.bb_pos, 6);
            return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : false;
        }

        /**
         * @returns org.apache.arrow.flatbuf.Type
         */
        typeType(): org.apache.arrow.flatbuf.Type {
            let offset = this.bb!.__offset(this.bb_pos, 8);
            return offset ? /**  */ (this.bb!.readUint8(this.bb_pos + offset)) : org.apache.arrow.flatbuf.Type.NONE;
        }

        /**
         * This is the type of the decoded value if the field is dictionary encoded.
         *
         * @param flatbuffers.Table obj
         * @returns ?flatbuffers.Table
         */
        type<T extends flatbuffers.Table>(obj: T): T | null {
            let offset = this.bb!.__offset(this.bb_pos, 10);
            return offset ? this.bb!.__union(obj, this.bb_pos + offset) : null;
        }

        /**
         * Present only if the field is dictionary encoded.
         *
         * @param org.apache.arrow.flatbuf.DictionaryEncoding= obj
         * @returns org.apache.arrow.flatbuf.DictionaryEncoding|null
         */
        dictionary(obj?: org.apache.arrow.flatbuf.DictionaryEncoding): org.apache.arrow.flatbuf.DictionaryEncoding | null {
            let offset = this.bb!.__offset(this.bb_pos, 12);
            return offset ? (obj || new org.apache.arrow.flatbuf.DictionaryEncoding).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
        }

        /**
         * children apply only to nested data types like Struct, List and Union. For
         * primitive types children will have length 0.
         *
         * @param number index
         * @param org.apache.arrow.flatbuf.Field= obj
         * @returns org.apache.arrow.flatbuf.Field
         */
        children(index: number, obj?: org.apache.arrow.flatbuf.Field): org.apache.arrow.flatbuf.Field | null {
            let offset = this.bb!.__offset(this.bb_pos, 14);
            return offset ? (obj || new org.apache.arrow.flatbuf.Field).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
        }

        /**
         * @returns number
         */
        childrenLength(): number {
            let offset = this.bb!.__offset(this.bb_pos, 14);
            return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
        }

        /**
         * User-defined metadata
         *
         * @param number index
         * @param org.apache.arrow.flatbuf.KeyValue= obj
         * @returns org.apache.arrow.flatbuf.KeyValue
         */
        customMetadata(index: number, obj?: org.apache.arrow.flatbuf.KeyValue): org.apache.arrow.flatbuf.KeyValue | null {
            let offset = this.bb!.__offset(this.bb_pos, 16);
            return offset ? (obj || new org.apache.arrow.flatbuf.KeyValue).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
        }

        /**
         * @returns number
         */
        customMetadataLength(): number {
            let offset = this.bb!.__offset(this.bb_pos, 16);
            return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startField(builder: flatbuffers.Builder) {
            builder.startObject(7);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset nameOffset
         */
        static addName(builder: flatbuffers.Builder, nameOffset: flatbuffers.Offset) {
            builder.addFieldOffset(0, nameOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param boolean nullable
         */
        static addNullable(builder: flatbuffers.Builder, nullable: boolean) {
            builder.addFieldInt8(1, +nullable, +false);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param org.apache.arrow.flatbuf.Type typeType
         */
        static addTypeType(builder: flatbuffers.Builder, typeType: org.apache.arrow.flatbuf.Type) {
            builder.addFieldInt8(2, typeType, org.apache.arrow.flatbuf.Type.NONE);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset typeOffset
         */
        static addType(builder: flatbuffers.Builder, typeOffset: flatbuffers.Offset) {
            builder.addFieldOffset(3, typeOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset dictionaryOffset
         */
        static addDictionary(builder: flatbuffers.Builder, dictionaryOffset: flatbuffers.Offset) {
            builder.addFieldOffset(4, dictionaryOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset childrenOffset
         */
        static addChildren(builder: flatbuffers.Builder, childrenOffset: flatbuffers.Offset) {
            builder.addFieldOffset(5, childrenOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param Array.<flatbuffers.Offset> data
         * @returns flatbuffers.Offset
         */
        static createChildrenVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
            builder.startVector(4, data.length, 4);
            for (let i = data.length - 1; i >= 0; i--) {
                builder.addOffset(data[i]);
            }
            return builder.endVector();
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number numElems
         */
        static startChildrenVector(builder: flatbuffers.Builder, numElems: number) {
            builder.startVector(4, numElems, 4);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset customMetadataOffset
         */
        static addCustomMetadata(builder: flatbuffers.Builder, customMetadataOffset: flatbuffers.Offset) {
            builder.addFieldOffset(6, customMetadataOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param Array.<flatbuffers.Offset> data
         * @returns flatbuffers.Offset
         */
        static createCustomMetadataVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
            builder.startVector(4, data.length, 4);
            for (let i = data.length - 1; i >= 0; i--) {
                builder.addOffset(data[i]);
            }
            return builder.endVector();
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number numElems
         */
        static startCustomMetadataVector(builder: flatbuffers.Builder, numElems: number) {
            builder.startVector(4, numElems, 4);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endField(builder: flatbuffers.Builder): flatbuffers.Offset {
            let offset = builder.endObject();
            return offset;
        }

        static createField(builder: flatbuffers.Builder, nameOffset: flatbuffers.Offset, nullable: boolean, typeType: org.apache.arrow.flatbuf.Type, typeOffset: flatbuffers.Offset, dictionaryOffset: flatbuffers.Offset, childrenOffset: flatbuffers.Offset, customMetadataOffset: flatbuffers.Offset): flatbuffers.Offset {
            Field.startField(builder);
            Field.addName(builder, nameOffset);
            Field.addNullable(builder, nullable);
            Field.addTypeType(builder, typeType);
            Field.addType(builder, typeOffset);
            Field.addDictionary(builder, dictionaryOffset);
            Field.addChildren(builder, childrenOffset);
            Field.addCustomMetadata(builder, customMetadataOffset);
            return Field.endField(builder);
        }
    }
}
/**
 * ----------------------------------------------------------------------
 * A Buffer represents a single contiguous memory segment
 *
 * @constructor
 */
export namespace org.apache.arrow.flatbuf {
    export class Buffer {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns Buffer
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): Buffer {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * The relative offset into the shared memory page where the bytes for this
         * buffer starts
         *
         * @returns flatbuffers.Long
         */
        offset(): flatbuffers.Long {
            return this.bb!.readInt64(this.bb_pos);
        }

        /**
         * The absolute length (in bytes) of the memory buffer. The memory is found
         * from offset (inclusive) to offset + length (non-inclusive).
         *
         * @returns flatbuffers.Long
         */
        length(): flatbuffers.Long {
            return this.bb!.readInt64(this.bb_pos + 8);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Long offset
         * @param flatbuffers.Long length
         * @returns flatbuffers.Offset
         */
        static createBuffer(builder: flatbuffers.Builder, offset: flatbuffers.Long, length: flatbuffers.Long): flatbuffers.Offset {
            builder.prep(8, 16);
            builder.writeInt64(length);
            builder.writeInt64(offset);
            return builder.offset();
        }

    }
}
/**
 * ----------------------------------------------------------------------
 * A Schema describes the columns in a row batch
 *
 * @constructor
 */
export namespace org.apache.arrow.flatbuf {
    export class Schema {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns Schema
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): Schema {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param Schema= obj
         * @returns Schema
         */
        static getRootAsSchema(bb: flatbuffers.ByteBuffer, obj?: Schema): Schema {
            return (obj || new Schema).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * endianness of the buffer
         * it is Little Endian by default
         * if endianness doesn't match the underlying system then the vectors need to be converted
         *
         * @returns org.apache.arrow.flatbuf.Endianness
         */
        endianness(): org.apache.arrow.flatbuf.Endianness {
            let offset = this.bb!.__offset(this.bb_pos, 4);
            return offset ? /**  */ (this.bb!.readInt16(this.bb_pos + offset)) : org.apache.arrow.flatbuf.Endianness.Little;
        }

        /**
         * @param number index
         * @param org.apache.arrow.flatbuf.Field= obj
         * @returns org.apache.arrow.flatbuf.Field
         */
        fields(index: number, obj?: org.apache.arrow.flatbuf.Field): org.apache.arrow.flatbuf.Field | null {
            let offset = this.bb!.__offset(this.bb_pos, 6);
            return offset ? (obj || new org.apache.arrow.flatbuf.Field).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
        }

        /**
         * @returns number
         */
        fieldsLength(): number {
            let offset = this.bb!.__offset(this.bb_pos, 6);
            return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
        }

        /**
         * @param number index
         * @param org.apache.arrow.flatbuf.KeyValue= obj
         * @returns org.apache.arrow.flatbuf.KeyValue
         */
        customMetadata(index: number, obj?: org.apache.arrow.flatbuf.KeyValue): org.apache.arrow.flatbuf.KeyValue | null {
            let offset = this.bb!.__offset(this.bb_pos, 8);
            return offset ? (obj || new org.apache.arrow.flatbuf.KeyValue).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
        }

        /**
         * @returns number
         */
        customMetadataLength(): number {
            let offset = this.bb!.__offset(this.bb_pos, 8);
            return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startSchema(builder: flatbuffers.Builder) {
            builder.startObject(3);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param org.apache.arrow.flatbuf.Endianness endianness
         */
        static addEndianness(builder: flatbuffers.Builder, endianness: org.apache.arrow.flatbuf.Endianness) {
            builder.addFieldInt16(0, endianness, org.apache.arrow.flatbuf.Endianness.Little);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset fieldsOffset
         */
        static addFields(builder: flatbuffers.Builder, fieldsOffset: flatbuffers.Offset) {
            builder.addFieldOffset(1, fieldsOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param Array.<flatbuffers.Offset> data
         * @returns flatbuffers.Offset
         */
        static createFieldsVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
            builder.startVector(4, data.length, 4);
            for (let i = data.length - 1; i >= 0; i--) {
                builder.addOffset(data[i]);
            }
            return builder.endVector();
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number numElems
         */
        static startFieldsVector(builder: flatbuffers.Builder, numElems: number) {
            builder.startVector(4, numElems, 4);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset customMetadataOffset
         */
        static addCustomMetadata(builder: flatbuffers.Builder, customMetadataOffset: flatbuffers.Offset) {
            builder.addFieldOffset(2, customMetadataOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param Array.<flatbuffers.Offset> data
         * @returns flatbuffers.Offset
         */
        static createCustomMetadataVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
            builder.startVector(4, data.length, 4);
            for (let i = data.length - 1; i >= 0; i--) {
                builder.addOffset(data[i]);
            }
            return builder.endVector();
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number numElems
         */
        static startCustomMetadataVector(builder: flatbuffers.Builder, numElems: number) {
            builder.startVector(4, numElems, 4);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endSchema(builder: flatbuffers.Builder): flatbuffers.Offset {
            let offset = builder.endObject();
            return offset;
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset offset
         */
        static finishSchemaBuffer(builder: flatbuffers.Builder, offset: flatbuffers.Offset) {
            builder.finish(offset);
        }

        static createSchema(builder: flatbuffers.Builder, endianness: org.apache.arrow.flatbuf.Endianness, fieldsOffset: flatbuffers.Offset, customMetadataOffset: flatbuffers.Offset): flatbuffers.Offset {
            Schema.startSchema(builder);
            Schema.addEndianness(builder, endianness);
            Schema.addFields(builder, fieldsOffset);
            Schema.addCustomMetadata(builder, customMetadataOffset);
            return Schema.endSchema(builder);
        }
    }
}
